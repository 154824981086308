import { BurgerBtn } from 'src/components/BurgerBtn'
import { data } from 'src/data'

const MobileMenu = ({
    navigationLinks,
    isMobileMenuOpened,
    onClose,
    openFormModal,
}) => {
    const mobileMenuClassNames = 'mobile-menu'
        + (isMobileMenuOpened ? ' mobile-menu--active' : '')

    const navigationLinksElement = navigationLinks.map(link => {
        return (
            <a onClick={onClose} key={link.id} className='link link--dark link--600' href={link.href}>{link.title}</a>
        )
    })

    const handleBookTicketBtn = () => {
        onClose()
        openFormModal()
    }

    return (
        <div className={mobileMenuClassNames}>
            <div className='mobile-menu__main-links'>
                {navigationLinksElement}
            </div>

            {/* <a
                className='mobile-menu__btn btn'
                href={data.nethouse}
                target='_blank'
                rel='noreferrer'
            >
                Купить билет
            </a> */}
            <button onClick={handleBookTicketBtn} className='mobile-menu__btn btn'>
                Оставить заявку
            </button>

            <div className='mobile-menu__footer-links'>
                <a
                    id='uis-phone-to-replace'
                    className='link link--dark'
                    href={data.phoneHref}
                >
                    {data.phone}
                </a>
                <a className='link link--dark' href={data.emailHref}>{data.email}</a>
                <a className='link link--dark' href={data.mainSiteHref} target='_blank' rel='noreferrer'>{data.mainSite}</a>
            </div>
        </div>
    )
}

export const MobileNavigation = ({
    isHeaderActive,
    navigationLinks,
    isMobileMenuOpened,
    handleMobileMenuOpen,
    handleMobileMenuClose,
    openFormModal,
}) => {
    const handleBurgerBtnClick = () => {
        isMobileMenuOpened
            ? handleMobileMenuClose()
            : handleMobileMenuOpen()
    }

    const isBtnDark = isMobileMenuOpened || isHeaderActive
    
    return (
        <>
            <BurgerBtn
                active={isMobileMenuOpened}
                dark={isBtnDark}
                handleClick={handleBurgerBtnClick}
                className='header__burger-btn'
            />

            <MobileMenu
                navigationLinks={navigationLinks}
                isMobileMenuOpened={isMobileMenuOpened}
                onClose={handleMobileMenuClose}
                openFormModal={openFormModal}
            />
        </>
    )
}