import { useEffect, useRef, useState } from 'react'
import { SelectArrow } from './SelectArrow'
import { SelectOptions } from './SelectOptions'
import { generateTarget } from 'src/utils'
import './style.scss'

export const Select = ({
    className,
    options = [],
    disabled,
    allowUnselect,
    ...props
}) => {
    const selectRef = useRef(null)
    const [active, setActive] = useState(false)

    const handleInputClick = () => setActive(state => !state)
    const isReadOnly = true

    const handleOptionClick = (value) => {
        return () => {
            if (props.onChange) {
                const newValue = props.value?.id === value.id
                    ? allowUnselect
                        ? {}
                        : value
                    : value

                props.onChange(generateTarget({ value: newValue, name: props.name, id: props.id }))
            }
        }
    }

    const valueName = props.value?.name
    const value = valueName || ''

    const handleInputBlur = (event) => {
        setActive(false)
        if (props.onBlur) {
            props.onBlur(event)
        }
    }
    
    // снимаем focus при закрытии списка кликом на input
    useEffect(() => {
        if (!active) {
            selectRef.current.querySelector('.select-input').blur()
        }
    }, [active])

    // классы
    const selectClassName = 'select'
        + (active ? ` select--active` : '')
        + (disabled ? ` select--disabled` : '')
        + (className ? ` ${className}` : '')

    return (
        <div
            ref={selectRef}
            className={selectClassName}
        >
            <input
                className='select-input input'
                onBlur={handleInputBlur}
                onClick={handleInputClick}

                id={props.id}
                name={props.name}
                type='text'
                value={value}
                placeholder={props.placeholder}
                
                error={props.error}
                readOnly={isReadOnly}
            />

            <SelectArrow
                active={active}
            />

            <SelectOptions
                active={active}
                value={props.value}
                options={options}
                handleOptionClick={handleOptionClick}
            />
        </div>
    )
}