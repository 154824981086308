import './style.scss'
import heroImage from './img/hero.jpg'
// import { heroImage, heroImageMobile } from 'src/data'

export const Hero = () => {
    return (
        
        <section id='start' className='hero' style={{ backgroundImage: `url('${heroImage}')`}}>
            {/* <picture>
                <source media='(max-width: 600px)' srcSet={heroImageMobile} />
                <img className='hero__image' src={heroImage} alt='' />
            </picture> */}

            <div className='hero__overlay'>

                <div className='container flex-v--c-c'>
                    <h1 className='hero__title title'>DDX Fitness <br /> Camp</h1>

                    <div className='hero__tags'>
                        <div className='tag tag--sm'>7 - 9 марта</div>
                        <div className='tag tag--sm'>Место уточняется</div>
                    </div>

                    <a href='#location' className='hero__btn btn'>Иду!</a>
                </div>

            </div>
        </section>
    )
}