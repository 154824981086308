import './style.scss'
import { data, feedback } from 'src/data'

import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper-bundle.min.css'

export const FeedBack = ({
    openFormModal,
}) => {

    const initialSLide = 5

    const memberName = (review) => review.name || 'Анонимный отзыв'

    return (
        <section className='feedback container section'>
            <p className='section__sub-title'>Впечатления участников</p>
            <h2 id='feedback' className='section__title title title--md scroll-mt'>Отзывы</h2>

            <div className='section__body'>

                <Swiper
                    id='feedback-swiper'
                    slidesPerView='auto'
                    initialSlide={initialSLide}
                    spaceBetween={10}
                    direction='horizontal'
                    loop={true}
                    className='feedback__swiper custom-location-swiper'
                >
                    {feedback.map((review, index) => (
                        <SwiperSlide key={index} >
                            <div className='feedback__card'>
                                <p className='feedback__card-name'>{memberName(review)}</p>
                                <p className='feedback__card-text'>{review.text}</p>
                            </div>
                        </SwiperSlide>))}
                </Swiper>

                {/* <a
                    className='feedback__btn btn'
                    href={data.nethouse}
                    target='_blank'
                    rel='noreferrer'
                >
                    Купить билет
                </a> */}

                <button
                    className='feedback__btn btn'
                    onClick={openFormModal}
                >
                    Оставить заявку
                </button>
            </div>
        </section>
    )
}