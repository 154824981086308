import offerPdf from './files/offer.pdf'


const eventSchedules = [
    {
        id: 1, title: '7 марта', events: [
            { title: 'Трансфер до отеля' },
            { title: 'Ужин' },
            { title: 'Открытие DDX Fitness Camp' },
        ]
    },
    {
        id: 2, title: '8 марта', events: [
            { title: 'Завтрак' },
            { title: 'Групповые тренировки' },
            { title: 'Мастер классы' },
            { title: 'Обед' },
            { title: 'Свободное время' },
            { title: 'Соревнования' },
            { title: 'Ужин' },
            { title: 'Тематическая вечеринка' },
        ]
    },
    {
        id: 3, title: '9 марта', events: [
            { title: 'Завтрак' },
            { title: 'Групповые тренировки' },
            { title: 'Мастер классы' },
            { title: 'Обед' },
            { title: 'Закрытие DDX Fitness Camp' },
            { title: 'Трансфер от отеля' },
        ]
    },
]

export {
    eventSchedules,
    offerPdf,
}