import { SelectOption } from './SelectOption'

export const SelectOptions = ({
    active,
    value,
    options,
    handleOptionClick,
}) => {
    const selectOptionsClassName = 'select-options'
        + (active ? ' select-options--active' : '')

    const optionElements = options?.map((currentOption, index) => {
        const isCurrentOptionActive = value?.id === currentOption.id

        return (
            <SelectOption
                key={index}
                active={isCurrentOptionActive}
                onClick={handleOptionClick(currentOption)}
            >
                {currentOption.name}
            </SelectOption>
        )
    })

    const isOptionElements = optionElements?.length

    return (
        <div className={selectOptionsClassName}>
            <div className='select-options_container'>
                {isOptionElements
                    ? optionElements
                    : <SelectOption
                        key='not-found'
                        disabled
                    >
                        Ничего не найдено...
                    </SelectOption>}
            </div>
        </div>
    )
}