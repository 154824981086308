import './style.scss'

export const SelectArrow = ({
    active,
}) => {
    const selectArrowClassName = 'select-arrow'
        + (active ? ' select-arrow--active' : '')
        
    return (
        <svg
            className={selectArrowClassName}
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path d='M6 9L12 15L18 9' stroke='#568977' strokeWidth='2' strokeLinecap='round' />
        </svg>
    )
}