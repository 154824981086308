import programEventsImage02 from '../img/program-events-02.png'
import programEventsImage03 from '../img/program-events-03.svg'
import programEventsImage04 from '../img/program-events-04.png'
import programEventsImage05 from '../img/program-events-05.svg'
import programEventsImage06 from '../img/program-events-06.png'

export const ProgramEvents = () => {
    return (
        <div className='program-events'>
            <div className='program-events__col'>
                <div className='program-events__item program-events__item--bg program-events__item--1st'>
                    <div style={{ zIndex: 1 }}>
                        <h4>Проживание в загородном отеле</h4>
                        {/* <p>C 3-х разовым питанием</p> */}
                    </div>
                </div>
                <div className='program-events__item program-events__item--2nd'>
                    <img src={programEventsImage02} alt='' />

                    <div>
                        <h4>Живописное расположение</h4>
                        {/* <p>Спа, тренажерный зал, батутный центр и многое другое</p> */}
                    </div>
                </div>
                
            </div>

            <div className='program-events__col'>
                <div className='program-events__item program-events__item--3rd'>
                    <img src={programEventsImage03} alt='' />

                    <div>
                        <h4>Интерактивные мастер-классы</h4>
                    </div>
                </div>
                <div className='program-events__item program-events__item--4th'>
                    <img src={programEventsImage04} alt='' />
                    
                    <div>
                        <h4>Командоорбразующие игры</h4>
                    </div>
                </div>
                <div className='program-events__item program-events__item--5th'>
                    <img src={programEventsImage05} alt='' />

                    <div>
                        <h4>Полное уединение с природой</h4>
                    </div>
                </div>
                
            </div>

            <div className='program-events__col'>
                <div className='program-events__item program-events__item--6th'>
                    <img src={programEventsImage06} alt='' />

                    <div>
                        {/* <h4>8 марта в кругу единомышленников</h4> */}
                        <h4>Уютные посиделки у костра</h4>
                    </div>
                </div>
                <div className='program-events__item program-events__item--bg program-events__item--7th'>
                    <div style={{ zIndex: 1 }}>
                        <h4>Любимые групповые программы</h4>
                    </div>
                </div>
            </div>
        </div>
    )
}