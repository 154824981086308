import './style.scss'

export const SelectOption = ({
    active,
    disabled,
    onClick,
    children,
}) => {
    const selectOptionClassName = 'select-option'
        + (active ? ' select-option--active' : '')
        + (disabled ? ' select-option--disabled' : '')

    return (
        <div
            className={selectOptionClassName}
            onMouseDown={onClick}
        >
            {children}
        </div>
    )
}