import './style.scss'
import { data, locationSlides } from 'src/data'
import { Gallery, YandexMap } from 'src/components'
import { useEffect, useRef, useState } from 'react'
import { useIsInViewport } from 'src/hooks'

const SLIDES = [...locationSlides, ...locationSlides]

export const Location = ({
    openFormModal,
}) => {
    const locationElement = useRef(null)
    const isIntersected = useIsInViewport(locationElement)
    const [isFirstIntersection, setFirstIntersection] = useState(false)

    useEffect(() => {
        if (!isFirstIntersection && isIntersected) {
            setFirstIntersection(true)
        }
    }, [isIntersected]) // eslint-disable-line

    return (
        <section ref={locationElement} className='location'>
            <div className='location__container'>
                <div className='container section'>
                    <p className='section__sub-title'>Место проведения</p>
                    <h2 id='location' className='section__title title title--md scroll-mt'>Отдых в загородном <br />комплексе</h2>

                    <div className='section__body'>
                        <div className='location__address'>
                            <div className='address'>
                                <h3 className='address__title'>Адрес:</h3>
                                <p className='address__value'>
                                    место проведения уточняется
                                </p>
                            </div>
                        </div>

                        <div className='flex--c-c'>
                            {/* <a
                                className='location__btn btn'
                                href={data.nethouse}
                                target='_blank'
                                rel='noreferrer'
                            >
                                Купить билет
                            </a> */}

                            <button
                                className='location__btn btn'
                                onClick={openFormModal}
                            >
                                Оставить заявку
                            </button>
                        </div>

                        {/* <div className='location__map'>
                            <YandexMap
                                shouldLoad={isFirstIntersection}
                                coordinates={[54.900942, 35.881717]}
                                // hintContent='Вечеринка здесь!'
                            />
                            <div className='map-filter'></div>
                        </div> */}

                        {/* <Gallery slides={SLIDES} id='location-swiper' /> */}
                    </div>
                </div>
            </div>
        </section>
    )
}